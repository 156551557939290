// extracted by mini-css-extract-plugin
export var backgroundImage = "ServicesPage-module--background-image--f1c27";
export var container = "ServicesPage-module--container--c0792";
export var contentInfo = "ServicesPage-module--content-info--d3844";
export var contentItem = "ServicesPage-module--content-item--48c40";
export var itemDescription = "ServicesPage-module--item-description--1660b";
export var itemDescriptionTitle = "ServicesPage-module--item-description-title--ffe59";
export var itemIcon = "ServicesPage-module--item-icon--1d743";
export var itemTextContainer = "ServicesPage-module--item-text-container--fa193";
export var itemTitle = "ServicesPage-module--item-title--f5b84";
export var itemTitleContainer = "ServicesPage-module--item-title-container--55e4b";
export var sectionTitle = "ServicesPage-module--section-title--909da";